import { Component, OnInit } from '@angular/core';
import { CredentialService } from '../../../service/credential.service';
import { Credential, CredentialSchema } from '../../../models/credential';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { UserService } from '../../../service/user.service';
import { OrganizationService } from '../../../service/organization.service'

@Component({
  selector: 'app-admin-credential-list',
  templateUrl: './admin-credential-list.component.html',
  styleUrls: ['./admin-credential-list.component.scss']
})
export class AdminCredentialListComponent implements OnInit {
  orderCourse: string;
  orderProgram: string;
  courseSearchKeyword: string = "";
  courseCredentialType: string = "course";
  coursePage: number = 1;
  coursePageSize: number = 10;
  courseTotal: number = 0;
  credentialCourseList: CredentialSchema[];

  programSearchKeyword: string = "";
  programCredentialType: string = "program";
  programPage: number = 1;
  programPageSize: number = 10;
  programTotal: number = 0;
  credentialProgramList: CredentialSchema[];

  loading: boolean = true;
  showOrganizationFilter: boolean = false;
  organizationCourse: string = ''
  organizationProgram: string = ''

  constructor(
    private credentialService: CredentialService,
    private titleService: Title,
    private route: ActivatedRoute,
    private userService: UserService,
    public organizationService: OrganizationService,
  ) {
    this.titleService.setTitle('Credential List');
  }

  ngOnInit(): void {
    this.loading = true;
    this.userService.getMyProfile().subscribe(data => {
      this.showOrganizationFilter = data.is_superuser;
    });
  }

  courseFilterCredential(): void {
    this.loading = true;
    this.credentialService.filterCredentialsSchema(this.courseSearchKeyword, this.courseCredentialType, this.coursePage.toString(), this.orderCourse, this.organizationCourse).subscribe(data => {
      this.loading = false;
      this.credentialCourseList = data.results;
      this.courseTotal = data.count;
    });
  }

  programFilterCredential(): void {
    this.loading = true;
    this.credentialService.filterCredentialsSchema(this.programSearchKeyword, this.programCredentialType, this.programPage.toString(), this.orderProgram, this.organizationProgram).subscribe(data => {
      this.loading = false;
      this.credentialProgramList = data.results;
      this.programTotal = data.count;
    });
  }

  courseParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, sort, pageSize } = params;
    this.coursePageSize = pageSize;
    this.coursePage = pageIndex;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'name';
    const sortOrder = (currentSort && currentSort.value) || 'ascend';
    this.orderCourse = sortOrder == 'descend' ? '-' + sortField: sortField;
    this.courseFilterCredential();
  }

  programParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, sort, pageSize } = params;
    this.programPageSize = pageSize;
    this.programPage = pageIndex;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'name';
    const sortOrder = (currentSort && currentSort.value) || 'ascend';
    this.orderProgram = sortOrder == 'descend' ? '-' + sortField: sortField;
    this.programFilterCredential();
  }

  searchCourse(event: any) {
    this.courseSearchKeyword = event.target.value;
    this.coursePage = 1;
    this.courseFilterCredential();
  }

  searchProgram(event: any) {
    this.programSearchKeyword = event.target.value;
    this.programPage = 1;
    this.programFilterCredential();
  }

  organizationCourseChange(value: string) {
    this.organizationCourse = value ? value : '';
    this.courseFilterCredential();
  }

  organizationProgramChange(value: string) {
    this.organizationProgram = value ? value : '';
    this.programFilterCredential();
  }

}
