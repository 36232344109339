import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AutocompleteObject } from 'src/app/models/autocomplete';

@Component({
  selector: 'app-endless-scroll-autocomplete',
  templateUrl: './endless-scroll-autocomplete.component.html',
  styleUrls: ['./endless-scroll-autocomplete.component.scss']
})
export class EndlessScrollAutocompleteComponent implements OnInit {

  @Input() placeholder: string;
  @Input() service: any;
  @Input() width: string;
  @Output() change = new EventEmitter<string>();
  
  isLoading: boolean = false;

  @Input() selectedText: string;
  @Input() options: AutocompleteObject[];
  more: boolean;
  currentQuery: string;
  currentPage: number = 1;
  nzFilterOption = () => true;

  constructor() { }

  ngOnInit(): void {
    this.onSearch('');
  }

  onSearch(value: string): void {
    this.service.filterAutocomplete(value, '').subscribe(data => {
      this.options = data.results;
      this.more = data.pagination.more;
      this.currentQuery = value;
      this.currentPage = 1;
    });
  }

  onBottomScroll(): void {
    if (!this.more) return;

    this.isLoading = true;
    this.service.filterAutocomplete(this.currentQuery, (++this.currentPage).toString()).subscribe(data => {
      this.isLoading = false;
      this.options = [...this.options, ...data.results];
      this.more = data.pagination.more;
    });
  }

  onChange(value: string): void {
    this.change.emit(value);
  }

}
