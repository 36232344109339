<div nz-row class="previous-page">
  <div nz-col nzSpan="24">
    <a class="btn-return" nz-button nzType="link" (click)="backClicked()"><i nz-icon nzType="left" nzTheme="outline"></i> Back to previous page </a>
  </div>
</div>

<nz-card nzTitle="Generate Credentials" class="credential-detail-card">
  <nz-table class="table-credential-schema-detail" [nzLoading]="loading" #credentialSchemaDetailTable [nzData]="credentialSchemaDetail" [nzFrontPagination]="false">
    <tbody>
      <tr *ngFor="let data of credentialSchemaDetailTable.data">
        <td class="label">{{ data.label }}</td>
        <td>{{ data.value }}</td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>
<nz-card nzTitle="Recipient" class="recipient-selected-card" [nzExtra]="findLearner">
  <nz-table
    #recipientSelectedTable
    class="recipient-selected-table"
    [nzData]="recipientSelected"
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzNoResult]="noData"
  >
    <thead>
      <tr>
        <th>Recipient name</th>
        <th>E-mail</th>
        <th nzWidth="10px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of recipientSelectedTable.data;">
        <td>{{ data.first_name }} {{ data.last_name }}</td>
        <td>{{ data.email }}</td>
        <td>
          <button
            nz-button
            class="btn-remove-recipient btn-danger"
            nzType="default"
            type="button"
            nzDanger
            nz-popconfirm
            nzPopconfirmTitle="Sure to remove?"
            (nzOnConfirm)="deleteRow(data.email)"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i> Remove
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ng-template #noData>
    <nz-empty nzNotFoundContent="No Data"></nz-empty>
  </ng-template>
  <div nz-row class="button-form">
    <div nz-col class="btn-group-submit">
      <button nz-button nzType="default" type="button" (click)="cancelRedirect()" class="btn-secondary">Cancel</button>
      <button nz-button nzType="default" type="submit" class="btn-submit" *ngIf="recipientSelected.length == 0" disabled>Generate</button>
      <button nz-button nzType="primary" type="submit" (click)="showGenerateModal()" class="btn-submit btn-primary" *ngIf="recipientSelected.length != 0">Generate</button>
      <nz-modal [(nzVisible)]="isGenerateConfirmModalShow" [nzTitle]="generateModalTitle" [nzContent]="generateModalContent" [nzFooter]="generateModalFooter" (nzOnCancel)="handleCancel()">
        <ng-template #generateModalTitle>ออกใบรับรองผลการเรียน ?</ng-template>
      
        <ng-template #generateModalContent>
            <p>กรุณาตรวจสอบข้อมูลของใบรับรองผลการเรียนด้านล่างก่อนดำเนินการออกใบรับรองผลการเรียน</p>
            <table id="revoke-confirm-data">
              <tbody>
                <tr>
                  <td class="label">ชื่อใบรับรอง :</td>
                  <td class="value">{{ credentialDetail.name }}</td>
                </tr>
                <tr>
                  <td class="label">อีเมล :</td>
                  <td class="value">{{ recipientSelected[0].email }}</td>
                </tr>
                <tr>
                  <td class="label">ชื่อผู้เรียน :</td>
                  <td class="value">{{ recipientSelected[0].first_name + " " + recipientSelected[0].last_name }}</td>
                </tr>
              </tbody>
            </table>
            <p>หมายเหตุ:   การดำเนินการนี้ไม่สามารถย้อนกลับได้  หากต้องการใบรับรองผลการเรียน คุณต้องดำเนินการออกใบรับรองใหม่</p>
        </ng-template>
      
        <ng-template #generateModalFooter>
            <button nz-button nzType="primary" (click)="generateCredential()" class="btn-submit btn-primary" [nzLoading]="isCredentialGenerating">Generate</button>
            <button nz-button nzType="primary" (click)="handleCancel()" class="btn-secondary">Cancel</button>
        </ng-template>
      </nz-modal>
    </div>
  </div>
</nz-card>
<ng-template #findLearner>
  <button nz-button type="button" nzType="primary" *ngIf="recipientSelected.length == 0" class="find-learner-btn btn-primary" (click)="showFindLearnerModal()">
    Find learner
  </button>
  <nz-modal
    [(nzVisible)]="findLearnerModal"
    nzTitle="Search Recipient"
    (nzOnCancel)="closeFindLearner()"
    [nzFooter]="null"
    nzWidth="1000px"
  >
    <ng-container *nzModalContent>
      <p class="search-label">Search Recipient</p>
      <nz-input-group nzSearch class="search-box" [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="Search name or e-mail" (change)="searchRecipient($event)" />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
      <br />
      <br />
      <nz-table
        #recipientTable
        class="recipient-list"
        [nzData]="recipientList"
        nzTableLayout="auto"
        [nzLoading]="isUserListLoading"
        [nzFrontPagination]="false"
        [nzPageIndex]="pageIndex"
        [nzTotal]="total"
        [nzPageSize]="pageSize"
        (nzQueryParams)="searchRecipientParamsChange($event)"
      >
        <thead>
          <tr>
            <th>Recipient name</th>
            <th nzWidth="300px">E-mail</th>
            <th nzWidth="10px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of recipientTable.data">
            <td>{{ data.first_name }} {{ data.last_name }}</td>
            <td>{{ data.email }}</td>
            <td>
              <button nz-button nzType="primary" (click)="selectRecipient(data)" type="button" class="btn-primary">Select</button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </ng-container>
  </nz-modal>
</ng-template>