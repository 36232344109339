import { Component } from '@angular/core';
import { GlobalConstants } from './core/global-constants';
import { UserService } from './service/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'credential-system';
  
  constructor(
    private userService: UserService,
  ) {
    this.userService.getMyProfile().subscribe(data => {
      GlobalConstants.isUserLoggedIn = true;
    });
  }
}
