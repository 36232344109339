import { Component, OnInit, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { UserService } from '../../service/user.service'
import { LOGOUT_URL, PROFILE_URL } from '../../service/settings'
import { GlobalConstants } from '../../core/global-constants'
import { Router } from '@angular/router';
import { OrganizationService } from '../../service/organization.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  username: string;
  logout_url: string;
  profile_url: string;
  isCollapsed = false;

  constructor(
    private userService: UserService,
    private titleService: Title,
    private injector: Injector,
    private organizationService: OrganizationService
  ) { }

  ngOnInit(): void {
    this.userService.getMyProfile().subscribe(data => {
      GlobalConstants.isUserLoggedIn = true;
      this.username = data.username;
      if (data.organization[0]['organization_code']) {
        let organization = '';
        this.organizationService.getAutocomplete().subscribe(org => {
          organization = org.results[0].text ? " (" + org.results[0].text + ")" : "";
          this.username = data.username + organization;
        });
      }
      const router = this.injector.get(Router);
      let group_user = [];
      data['groups'].forEach(group => {
        if (group.name == 'Admin') {
          group_user.push(group.name)
        }
      });
      if (group_user.length == 0) {
        router.navigate(['error', '403']);
      };
    })
    this.titleService.setTitle('Credential management');

    this.logout_url = LOGOUT_URL;
    this.profile_url = PROFILE_URL;
  }

}
