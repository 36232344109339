import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { BASE_URL, LOGIN_URL } from './settings'
import { Credential, CredentialStatus, PaginatedCredential, CredentialSchema, PaginatedCredentialSchema } from '../models/credential'

@Injectable({
  providedIn: 'root'
})
export class CredentialService {
  private credentialUrl: string = BASE_URL + '/api/credential/v1/credential';
  private autocompleteUrl: string = BASE_URL + '/api/credential/v1/credential/autocomplete';
  private credentialSchemaUrl: string = BASE_URL + '/api/credential/v1/schema';

  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private injector: Injector,
  ) { }

  ngOnInit() {

  }

  getCredentials(): Observable<PaginatedCredential> {
    return this.http.get<PaginatedCredential>(this.credentialUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError<PaginatedCredential>('getCredentials'))
      );
  }

  getCredential(id: string): Observable<Credential> {
    const url = `${this.credentialUrl}/${id}`;

    return this.http.get<Credential>(url, this.httpOptions)
      .pipe(
        catchError(this.handleError<Credential>(`getCredential id=${id}`))
      );
  }

  updateStatusCredential(data: CredentialStatus, uuid: string): Observable<CredentialStatus> {
    return this.http.put<CredentialStatus>(
      `${this.credentialUrl}/${uuid}/status/`,
      data,
      this.httpOptions
    ).pipe(
      catchError(this.handleError<CredentialStatus>('updateCredential'))
    );
  }

  filterCredential(search: string, size: string, page: string, learning_opp: string, order: string, status: string): Observable<PaginatedCredential> {
    let query: HttpParams = new HttpParams()
      .set('search', search)
      .set('page_size', size)
      .set('page', page)
      .set('from_learning_opp_id', learning_opp)
      .set('order', order ? order: '')
      .set('status', status);

    if (!query.keys().length) {
      return this.getCredentials();
    }

    return this.http.get<PaginatedCredential>(
      this.credentialUrl,
      {
        params: query,
        headers: new HttpHeaders({ 
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      catchError(this.handleError<PaginatedCredential>('filterCredential'))
    );
  }

  getCredentialsSchema(): Observable<PaginatedCredentialSchema> {
    return this.http.get<PaginatedCredentialSchema>(this.credentialSchemaUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError<PaginatedCredentialSchema>('getCredentialsSchema'))
      );
  }

  getCredentialSchema(id: string): Observable<CredentialSchema> {
    const url = `${this.credentialSchemaUrl}/${id}`;

    return this.http.get<CredentialSchema>(url, this.httpOptions)
      .pipe(
        catchError(this.handleError<CredentialSchema>(`getCredentialSchema id=${id}`))
      );
  }

  filterCredentialsSchema(search: string, type: string, page: string, order: string, organization: string): Observable<PaginatedCredentialSchema> {
    let query: HttpParams = new HttpParams()
    .set('type', type)
    .set('search', search)
    .set('page', page)
    .set('organization', organization)
    .set('order', order ? order: '');

    if (!query.keys().length) {
      return this.getCredentialsSchema();
    }

    return this.http.get<PaginatedCredentialSchema>(
        this.credentialSchemaUrl,
        {
          params: query,
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      ).pipe(
      catchError(this.handleError<PaginatedCredentialSchema>('filterCredentialsSchema'))
    );
  }

  createCredential(data: Credential ): Observable<Credential> {
    return this.http.post<Credential>(
      `${this.credentialUrl}/`,
      data,
      this.httpOptions
    ).pipe(
      catchError(this.handleError<Credential>('createCredential'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const router = this.injector.get(Router);
      const status = error.status;
      if (Error instanceof HttpErrorResponse) {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
      } else {
        // TODO: better job of transforming error for user consumption
        console.error(`${operation} failed: ${error.message}`);
      }

      return of(error as T);
    };
  }
}
