import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { Autocomplete } from '../models/autocomplete' ;
import { BASE_URL } from './settings';
import { LOGIN_URL } from './settings';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private autocompleteUrl: string = BASE_URL + '/api/organization/v1/organization/autocomplete/'

  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private injector: Injector,
  ) { }

  getAutocomplete(): Observable<Autocomplete> {
    return this.http.get<Autocomplete>(this.autocompleteUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError<Autocomplete>('organizationAutocomplete'))
      );
  }

  filterAutocomplete(q: string, page: string): Observable<Autocomplete> {
    let query: HttpParams = new HttpParams()
      .set('q', q)
      .set('page', page);

    if (!query.keys().length) {
      return this.getAutocomplete();
    }

    return this.http.get<Autocomplete>(
        this.autocompleteUrl,
        {
          params: query,
          headers: new HttpHeaders({ 
            'Content-Type': 'application/json'
          })
        }
      ).pipe(
      catchError(this.handleError<Autocomplete>('searchOrganizationAutocomplete'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const router = this.injector.get(Router);
      const status = error.status;
      if (Error instanceof HttpErrorResponse) {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
      } else {
        // TODO: better job of transforming error for user consumption
        console.error(`${operation} failed: ${error.message}`);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
