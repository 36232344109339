import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { BASE_URL, LOGIN_URL } from './settings'
import { User, PaginatedUser } from '../models/user'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userUrl: string = BASE_URL + '/api/user/v1/user';
  private learnerUrl: string = BASE_URL + '/api/user/v1/learner';

  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'
    })
  };


  constructor(
    private http: HttpClient,
    private injector: Injector
  ) { }

  ngOnInit() {
  }

  getMyProfile(): Observable<User> {
    return this.http.get<User>(this.userUrl + '/me/', this.httpOptions)
      .pipe(
        catchError(this.handleError<User>('getMyProfile'))
      );
  }

  getUserList(): Observable<PaginatedUser> {
    return this.http.get<PaginatedUser>(this.userUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError<PaginatedUser>('getUserList'))
      );
  }

  getUser(id: string): Observable<User> {
    const url = `${this.userUrl}/${id}`;

    return this.http.get<User>(url, this.httpOptions)
      .pipe(
        catchError(this.handleError<User>(`getUser id=${id}`))
      );
  }

  filterUser(search: string, page: string, page_size: string): Observable<PaginatedUser> {
    let query: HttpParams = new HttpParams()
    .set('page_size', page_size)
    .set('search', search)
    .set('page', page);

    if (!query.keys().length) {
      return this.getUserList();
    }

    return this.http.get<PaginatedUser>(
        this.userUrl,
        {
          params: query,
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      ).pipe(
      catchError(this.handleError<PaginatedUser>('filterUser'))
    );
  }

  filterLearner(search: string, page: string, page_size: string): Observable<PaginatedUser> {
    let query: HttpParams = new HttpParams()
    .set('page_size', page_size)
    .set('search', search)
    .set('page', page);

    if (!query.keys().length) {
      return this.getUserList();
    }

    return this.http.get<PaginatedUser>(
        this.learnerUrl,
        {
          params: query,
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      ).pipe(
      catchError(this.handleError<PaginatedUser>('filterUser'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const router = this.injector.get(Router);
      const status = error.status;
      if (Error instanceof HttpErrorResponse) {
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead
      } else {
        // TODO: better job of transforming error for user consumption
        console.error(`${operation} failed: ${error.message}`);
      }

      return of(error as T);
    };
  }
}
