import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CredentialService } from '../../../service/credential.service';
import { UserService } from '../../../service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../models/user';
import { CredentialSchema } from '../../../models/credential';
import { Title } from '@angular/platform-browser';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-admin-generate-credential',
  templateUrl: './admin-generate-credential.component.html',
  styleUrls: ['./admin-generate-credential.component.scss']
})
export class AdminGenerateCredentialComponent implements OnInit {

  paramId: string = "";
  findLearnerModal: boolean = false;
  pageIndex: number = 1;
  searchRecipientKeyword: {} = {};
  pageSize: number = 10;
  total: number = 0;
  recipientList: User[] = [];
  recipientSelected: User[] = [];
  credentialDetail: CredentialSchema;
  credentialSchemaDetail = [];
  description: string = "";
  loading: boolean = true;
  isUserListLoading: boolean = false;

  isCredentialGenerating: boolean = false;
  isGenerateConfirmModalShow: boolean = false;

  constructor(
    private _location: Location,
    private credentialService: CredentialService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private notification: NzNotificationService,
    private titleService: Title,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.paramId = params['id'];
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.titleService.setTitle('Generate Credentials');

    this.credentialService.getCredentialSchema(this.paramId).subscribe(data => {
      this.loading = false;
      this.credentialDetail = data;
      data.metadata_list.forEach(metadata => {
        if (metadata.metadata_term == "description") {
          this.description = metadata.metadata_value;
        }
      })

      this.credentialSchemaDetail = [
        {
          label: "Course Code",
          value: data.registry_identifier
        },
        {
          label: 'Course Name',
          value: data.name
        },
        {
          label: 'Description' ,
          value: this.description
        }
      ]
    });
  }

  generateCredential(): void {
    if (this.recipientSelected.length == 1) {
      this.isCredentialGenerating = true;
      let value;
      value = {
        metadata_list: [],
        name: this.credentialDetail.name,
        type: this.credentialDetail.type + "_credential",
        subject_id: this.recipientSelected[0].email,
        subject_name: this.recipientSelected[0].first_name + " " + this.recipientSelected[0].last_name,
        issuer: this.credentialDetail.provider.provider_code,
        expiration_date: null,
        from_learning_opp_id: this.credentialDetail.id
      };

      this.credentialService.createCredential(value).subscribe(data => {
        this.isCredentialGenerating = false;
        if(data && data.id) {
          this.route.navigate(['/admin/credential/'+ data.id +'/detail']);
        } else {
          this.createNotification('warning', data['message']);
        }
      })
    } else {
      this.createNotification('warning', 'Please select recipient');
    }
  }

  backClicked() {
    this._location.back();
  }

  cancelRedirect(): void {
    if ( this.paramId &&  this.paramId != null ) {
      this.route.navigate(['/admin/credential/'+  this.paramId +'/list']);
    }
    else {
      this.route.navigate(['/admin/credential']);
    }
  }

  showFindLearnerModal(): void {
    this.findLearnerModal = true;
  }

  closeFindLearner(): void {
    this.findLearnerModal = false;
  }

  searchRecipient(event: any): void {
    this.isUserListLoading = true;
    let keyword = event.target ? event.target.value : "";
    this.searchRecipientKeyword = {
      inputRecipient: keyword,
      page: this.pageIndex,
      page_size: this.pageSize
    };

    this.userService.filterLearner(keyword, this.pageIndex.toString(), this.pageSize.toString()).subscribe(data => {
      this.recipientList = data.results;
      this.total = data.count;
      this.isUserListLoading = false;
    })
  }

  searchRecipientParamsChange(params: any): void {
     this.pageIndex = params.pageIndex;
     this.searchRecipient(this.searchRecipientKeyword);
   }

  selectRecipient(data): void {
    this.findLearnerModal = false;
    this.recipientSelected = [data];
  }

  deleteRow(email: string): void {
    this.recipientSelected = this.recipientSelected.filter(d => d.email !== email);
  }

  createNotification(type: string, message: string): void {
    this.notification.create(type, 'Notification', message);
  }

  showGenerateModal(): void {
    this.isGenerateConfirmModalShow = true;
  }

  handleCancel(): void {
    this.isGenerateConfirmModalShow = false;
  }
}
