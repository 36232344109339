import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/** config angular i18n **/
import { registerLocaleData, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

/** config ng-zorro-antd i18n **/
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

import { HttpRequestInterceptor} from './interceptor/httprequest.interceptor';
import { ErrorDialogService } from './core/error/service/error-dialog.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgZorroAntdModule } from './modules/ng-zorro-antd.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminCredentialDetailComponent } from './admin/credentials/admin-credential-detail/admin-credential-detail.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminCredentialListComponent } from './admin/credentials/admin-credential-list/admin-credential-list.component';
import { AdminCredentialRecipientComponent } from './admin/credentials/admin-credential-recipient/admin-credential-recipient.component';
import { AdminGenerateCredentialComponent } from './admin/credentials/admin-generate-credential/admin-generate-credential.component';
import { EndlessScrollAutocompleteComponent } from './shared/endless-scroll-autocomplete/endless-scroll-autocomplete.component';
import { PageForbiddenComponent } from './core/error/page-forbidden/page-forbidden.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminCredentialDetailComponent,
    AdminLayoutComponent,
    AdminCredentialListComponent,
    AdminCredentialRecipientComponent,
    AdminGenerateCredentialComponent,
    EndlessScrollAutocompleteComponent,
    PageForbiddenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    ErrorDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
