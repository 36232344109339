<table style="min-height: 80vh;;width:100%;"><tr><td>
    <div class="error-page" fxLayout='column' fxLayoutAlign="space-around center">
    
        <h1><b>Something went wrong</b></h1>
        <p class="error-description">We're so sorry this happened Please, check again later</p>
        <section>
                      <button nz-button class="button" ><a class="colorlink" mat-raised-button href="https://support.thaimooc.org/help-center/tickets/new" target="_blank">Report</a></button>
                      &nbsp;&nbsp;<button nz-button  nzType="primary"  (click)="handleCancel()" class="btn-primary button">Back</button>
              
        </section>
      
    </div>
    </td></tr></table>