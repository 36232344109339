import { Injectable, Injector } from '@angular/core';
import { ErrorDialogService } from "../core/error/service/error-dialog.service";
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LOGIN_URL } from '../service/settings';
import { GlobalConstants } from '../core/global-constants';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        public errorDialogService: ErrorDialogService,
        private injector: Injector
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // Use this for console logging
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const router = this.injector.get(Router);
                let errortext: string = error.statusText;

                // Catch error detail from backend
                if(error.error) {
                    errortext = error.error.detail;
                }

                // Redirect
                if ((error.status == 403 || error.status == 401) && !GlobalConstants.isUserLoggedIn) {
                    window.location.href = LOGIN_URL + window.location.pathname
                } else if (error.status == 403 && GlobalConstants.isUserLoggedIn) {
                    router.navigate(['admin/403']);
                } else if (error.status == 404) {
                    router.navigate(['error', status]);
                } else {
                    // Open error dialog
                    this.errorDialogService.openDialog(errortext);
                }

                return throwError(error);
            }));
    }
}
