<div nz-row>
  <div nz-col nzSpan="24">
    <a class="btn-return" nz-button nzType="link" routerLink="/admin/credential/{{ learningopp_id }}/list"><i nz-icon nzType="left" nzTheme="outline"></i> Previous Page </a>
  </div>
</div>
<nz-card class="credential-detal" nzTitle="Credential Recipient Detail" [nzExtra]="extraTemplate">
  <nz-table class="table-detail" #basicTable [nzData]="listOfData" [nzFrontPagination]="false">
    <tbody>
      <tr *ngFor="let data of basicTable.data">
        <td class="label">{{ data.key }}</td>
        <td>{{ data.value }}</td>
      </tr>
    </tbody>
  </nz-table>
</nz-card>
<ng-template #extraTemplate>
  <a id="hidden-download-credential-link" href="{{downloadFile}}" download *ngIf="downloadFile">Download file</a>
  <button class="download-credential btn-primary" nz-button nzType="default" *ngIf="downloadFile" (click)="handleDownloadCredentialClicked()" [nzLoading]="isDownloadButtonJustClicked">Download file</button>
  <button *ngIf="credentialStatus == 'active'; else showStatus" class="revoke-btn btn-danger" nz-button nzType="default" nzDanger (click)="showChangeStatusModal()" [nzLoading]="isRevoking">Revoke</button>
  <ng-template #showStatus>
    <button nz-button nzType="default" disabled>Revoked</button>
  </ng-template>
  <nz-modal [(nzVisible)]="isRevokeConfirmModalShow" [nzTitle]="changeStatusModalTitle" [nzContent]="changeStatusModalContent" [nzFooter]="changeStatusModalFooter" (nzOnCancel)="handleCancel()">
    <ng-template #changeStatusModalTitle>ต้องการเพิกถอนใบรับรองผลการเรียนนี้หรือไม่ ?</ng-template>
  
    <ng-template #changeStatusModalContent>
        <p>คุณพยายามเพิกถอนใบรับรองผลการเรียนนี้ใช่หรือไม่ กรุณาตรวจสอบข้อมูลของใบรับรองผลการเรียนด้านล่างก่อนดำเนินการเพิกถอน</p>
        <table id="revoke-confirm-data">
          <tbody>
            <tr>
              <td class="label">ชื่อใบรับรอง :</td>
              <td class="value">{{title}}</td>
            </tr>
            <tr>
              <td class="label">อีเมล :</td>
              <td class="value">{{credential.subject_id}}</td>
            </tr>
            <tr>
              <td class="label">ชื่อผู้เรียน :</td>
              <td class="value">{{credential.subject_name}}</td>
            </tr>
            <tr>
              <td class="label">ออกให้ ณ วันที่ :</td>
              <td class="value">{{credential.issuuance_date | date:'dd/MM/yyyy'}}</td>
            </tr>
          </tbody>
        </table>
        <p>หมายเหตุ:   การดำเนินการนี้ไม่สามารถย้อนกลับได้  หากต้องการใบรับรองผลการเรียน คุณต้องดำเนินการออกใบรับรองใหม่</p>
    </ng-template>
  
    <ng-template #changeStatusModalFooter>
        <button nz-button nzType="default" (click)="handleChangeStatus('revoked')" [nzLoading]="isConfirmLoading" class="btn-confirm btn-danger">Revoke</button>
        <button nz-button nzType="primary" (click)="handleCancel()" class="btn-secondary">Cancel</button>
    </ng-template>
  </nz-modal>
</ng-template>
