import { Component, OnInit } from '@angular/core';
import { CredentialService } from '../../../service/credential.service';
import { ActivatedRoute } from '@angular/router';
import { Credential, CredentialSchema } from '../../../models/credential';
import { Title } from '@angular/platform-browser';

import { NzTableQueryParams } from 'ng-zorro-antd/table';

@Component({
  selector: 'app-admin-credential-recipient',
  templateUrl: './admin-credential-recipient.component.html',
  styleUrls: ['./admin-credential-recipient.component.scss']
})
export class AdminCredentialRecipientComponent implements OnInit {

  status: string = '';
  loading: boolean = true;
  paramId: string = "";
  credentialRecipientDetail: CredentialSchema;
  searchKeyword: string = "";
  recipientPageSize: number = 10;
  recipientPage: number = 1;
  recipientTotal: number = 0;
  registry_identifier: string = "";
  description: string = "";
  learningopp_id: string = "";
  order: string;
  credentialRecipientList: Credential[];
  listOfColumn = [
    {
      title: 'Recipient',
      key: 'subject_name'
    },
    {
      title: 'E-mail',
      key: 'subject_id'
    },
    {
      title: 'Status',
      key: 'status'
    },
    {
      title: 'Recieved date',
      key: 'issuuance_date'
    },
    {
      title: 'Revoked date',
      key: 'revoked_date'
    }
  ]
  optionList = [
    { label: 'Active', value: 'active' },
    { label: 'Revoked', value: 'revoked' },
    { label: 'Expired', value: 'expired' }
  ];
  selectedValue = null;
  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.value === o2.value : o1 === o2);

  constructor(
    private credentialService: CredentialService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.paramId = params['id'];
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Credential Recipien List');
    this.loading = true;

    this.credentialService.getCredentialSchema(this.paramId).subscribe(data => {
      data.metadata_list.forEach(metadata => {
        if ( metadata.metadata_term == "description" ) {
          this.description = metadata.metadata_value;
        }
      });
      this.credentialRecipientDetail = data;
      this.registry_identifier = data.registry_identifier;
      this.learningopp_id = data.id;
      this.loading = false;

      this.credentialService.filterCredential(this.searchKeyword, this.recipientPageSize.toString(), this.recipientPage.toString(), data.id, this.order, this.status).subscribe(data => {
        this.credentialRecipientList = data.results;
        this.recipientPageSize = data.results.length;
        this.recipientTotal = data.count;
        this.credentialRecipientDetail.total_credential = data.count.toString();
      })
    });
  }

  filterCredential(): void {
    this.loading = true;
    this.credentialService.filterCredential(this.searchKeyword, this.recipientPageSize.toString(), this.recipientPage.toString(), this.learningopp_id, this.order, this.status).subscribe(data => {
      this.loading = false;
      this.credentialRecipientList = data.results;
      this.recipientTotal = data.count;
    });
  }

  searchRecipient(event: any) {
    this.searchKeyword = event.target.value;
    this.recipientPage = 1;
    this.filterCredential();
  }

  queryParamsChange(params: NzTableQueryParams): void {
    const { pageIndex, sort, pageSize } = params;
    this.recipientPageSize = pageSize;
    this.recipientPage = pageIndex;
    const currentSort = sort.find(item => item.value !== null);
    const sortField = (currentSort && currentSort.key) || 'subject_name';
    const sortOrder = (currentSort && currentSort.value) || 'ascend';
    this.order = sortOrder == 'descend' ? '-' + sortField: sortField;
    this.filterCredential();
  }

  handleStatus(data: { label: string; value: string; }): void {
    this.status = data ? data.value : '';
    this.filterCredential();
  }

}
