<nz-layout *ngIf="username">
    <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
      <div class="logo"></div>
      <ul nz-menu nzMode="inline" class="sider-menu">
        <li nz-menu-item nzSelected routerLink="/admin/credential" nzSelected>
            <i nz-icon nzType="database" nzTheme="outline"></i>
            <span>View Credential</span>
        </li>
      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header class="header">
        <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'" (click)="isCollapsed = !isCollapsed"></i>
        <ul nz-menu nzMode="horizontal" class="header-menu">
            <li nz-submenu [nzTitle]="usernameTemplate">
                <ul>
                    <li nz-menu-item>
                        <a href="{{profile_url}}">
                            <i nz-icon nzType="profile" nzTheme="outline"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li nz-menu-item>
                        <a href="{{logout_url}}">
                            <i nz-icon nzType="logout" nzTheme="outline"></i>
                            <span>Log out</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
      </nz-header>
      <nz-layout class="inner-layout">
        <nz-content>
            <router-outlet></router-outlet>
        </nz-content>
        <nz-footer>สงวนลิขสิทธิ์ © 2565 โครงการมหาวิทยาลัยไซเบอร์ไทย.</nz-footer>
      </nz-layout>
    </nz-layout>
  </nz-layout>
<ng-template #usernameTemplate>
    <svg id="user-icon" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.10255106,18.1351061 C4.7170266,16.0581859 8.01891846,14.4720277 12,14.4720277 C15.9810815,14.4720277 19.2829734,16.0581859 19.8974489,18.1351061 C21.215206,16.4412566 22,14.3122775 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,14.3122775 2.78479405,16.4412566 4.10255106,18.1351061 Z M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z M12,13 C9.790861,13 8,11.209139 8,9 C8,6.790861 9.790861,5 12,5 C14.209139,5 16,6.790861 16,9 C16,11.209139 14.209139,13 12,13 Z" fill="currentColor"></path>
    </svg>
    <span>{{username}}</span>
    <i nz-icon nzType="down" nzTheme="outline"></i>
</ng-template>