import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './core/error/page-not-found/page-not-found.component'
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AdminCredentialDetailComponent } from './admin/credentials/admin-credential-detail/admin-credential-detail.component';
import { AdminCredentialListComponent } from './admin/credentials/admin-credential-list/admin-credential-list.component'
import { AdminCredentialRecipientComponent } from './admin/credentials/admin-credential-recipient/admin-credential-recipient.component';
import { AdminGenerateCredentialComponent } from './admin/credentials/admin-generate-credential/admin-generate-credential.component';
import { PageForbiddenComponent } from './core/error/page-forbidden/page-forbidden.component';

const routes: Routes = [
  // Admin routes goes here
  { path:'', redirectTo: '/admin/credential', pathMatch: 'full' },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      { path:'', redirectTo: '/admin/credential', pathMatch: 'full' },
      { path: 'credential/:id/detail', component: AdminCredentialDetailComponent },
      { path: 'credential', component: AdminCredentialListComponent },
      { path: 'credential/:id/list', component: AdminCredentialRecipientComponent },
      { path: 'credential/:id/generate', component: AdminGenerateCredentialComponent },
      { path: '403', component: PageForbiddenComponent }
    ]
  },
  { path: '403', component: PageForbiddenComponent },
  { path: 'error/:status', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
