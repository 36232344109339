import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  message: string;

  constructor(
    private route: ActivatedRoute,
  ) { }
  handleCancel(): void {
    window.history.back();
  
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let status = params['status'];
      if (status == 500 || status == 0) {
        this.message = `Internal Server Error (500)`;
      } else if (status == 400) {
        this.message = `Bad Request (400)`;
      } else if (status == 401) {
        this.message = `Unauthorized (401)`;
      } else if (status == 403) {
        this.message = `Forbidden (403)`;
      } else {
        this.message = `Page Not Found (404)`;
      }
   });
  }

}
