
<nz-card>
	<div class="credential-list">
		<div nz-row>
			<div nz-col nzSpan="24" class="header">
				<h1>Credential List</h1>
			</div>
		</div>
		<nz-tabset>
			<nz-tab nzTitle="Courses">
				<nz-card [nzExtra]="courseSearchFilter">
					<nz-table
						class="credential-course-list"
						#CDCourseList
						[nzData]="credentialCourseList"
						[nzNoResult]="noDataTemplate"
						[nzLoading]="loading"
						nzTableLayout="auto"
						(nzQueryParams)="courseParamsChange($event)"
						[nzFrontPagination]="false"
						[nzTotal]="courseTotal"
						[nzPageSize]="coursePageSize"
						[nzPageIndex]="coursePage"
					>
						<thead>
							<tr>
								<th nzColumnKey="name" [nzSortFn]="true">Course name</th>
								<th nzColumnKey="organization" [nzSortFn]="true">Organization</th>
								<th nzColumnKey="status" [nzSortFn]="true">Course Status</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let data of CDCourseList.data;">
								<td>
									<a routerLink="/admin/credential/{{data.id}}/list">{{ data.name }}</a>
									<p>{{ data.registry_identifier }}</p>
								</td>
								<td>
									<span nz-tooltip nzTooltipTitle="{{ data.organization.name }}" nzTooltipPlacement="bottom">
											{{ data.organization.organization_code }}
									</span>
								</td>
								<td>{{ data.status }}</td>
							</tr>
						</tbody>
					</nz-table>
					<ng-template #noDataTemplate>
						<nz-empty nzNotFoundContent="No Data"></nz-empty>
					</ng-template>
				</nz-card>
				<ng-template #courseSearchFilter>
					<div class="search-filter">
						<nz-input-group class="search-box" [nzSuffix]="suffixIconSearch">
							<input type="text" nz-input placeholder="Search Course Name" (change)="searchCourse($event)" />
						</nz-input-group>
						<ng-template #suffixIconSearch>
							<i nz-icon nzType="search"></i>
						</ng-template>
						<app-endless-scroll-autocomplete *ngIf="showOrganizationFilter" placeholder="Select organization"
								[service]="organizationService" (change)="organizationCourseChange($event)" min-width="250px">
						</app-endless-scroll-autocomplete>
					</div>
				</ng-template>
			</nz-tab>
			<nz-tab nzTitle="Programs">
				<nz-card [nzExtra]="programSearchFilter">
					<nz-table
						class="credential-program-list"
						#CDProgramList
						[nzData]="credentialProgramList"
						[nzNoResult]="noDataTemplate"
						[nzLoading]="loading"
						nzTableLayout="auto"
						(nzQueryParams)="programParamsChange($event)"
						[nzFrontPagination]="false"
						[nzTotal]="programTotal"
						[nzPageSize]="programPageSize"
						[nzPageIndex]="programPage"
					>
						<thead>
							<tr>
								<th nzColumnKey="name" [nzSortFn]="true">Program name</th>
								<th nzColumnKey="organization" [nzSortFn]="true">Organization</th>
								<th nzColumnKey="status" [nzSortFn]="true">Program Status</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let data of CDProgramList.data;">
								<td>
									<a routerLink="/admin/credential/{{data.id}}/list">{{ data.name }}</a>
									<p>{{ data.registry_identifier }}</p>
								</td>
								<td>
									<span nz-tooltip nzTooltipTitle="{{ data.organization.name }}" nzTooltipPlacement="bottom">
											{{ data.organization.organization_code }}
									</span>
								</td>
								<td>{{ data.status }}</td>
							</tr>
						</tbody>
					</nz-table>
					<ng-template #noDataTemplate>
						<nz-empty nzNotFoundContent="No Data"></nz-empty>
					</ng-template>
				</nz-card>
				<ng-template #programSearchFilter>
					<div class="search-filter">
						<nz-input-group class="search-box" [nzSuffix]="suffixIconSearch">
							<input type="text" nz-input placeholder="Search Program Name" (change)="searchProgram($event)" />
						</nz-input-group>
						<ng-template #suffixIconSearch>
							<i nz-icon nzType="search"></i>
						</ng-template>
						<app-endless-scroll-autocomplete *ngIf="showOrganizationFilter" placeholder="Select organization"
								[service]="organizationService" (change)="organizationProgramChange($event)" min-width="250px">
						</app-endless-scroll-autocomplete>
					</div>
				</ng-template>
			</nz-tab>
		</nz-tabset>
	</div>
</nz-card>