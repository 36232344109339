<nz-select
    nzShowSearch
    nzServerSearch
    nzAllowClear
    [nzDropdownRender]="renderTemplate"
    [nzPlaceHolder]="placeholder"
    [(ngModel)]="selectedText"
    [nzFilterOption]="nzFilterOption"
    (nzOnSearch)="onSearch($event)"
    (nzScrollToBottom)="onBottomScroll()"
    (ngModelChange)="onChange($event)"

    [ngStyle]="{'width': width}"
    class="autocomplete"
>
    <nz-option *ngFor="let o of options" [nzLabel]="o.text" [nzValue]="o.id"> </nz-option>
</nz-select>
<ng-template #renderTemplate>
    <nz-spin *ngIf="isLoading"></nz-spin>
</ng-template>