import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Injectable()
export class ErrorDialogService {
  constructor(
    private notification: NzNotificationService
  ) { }

  openDialog(text: string): any {
    this.notification.error('Error', text, { nzDuration: 0 });
  }
}
