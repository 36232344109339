<div nz-row>
  <div nz-col nzSpan="24">
    <a class="btn-return" nz-button nzType="link" routerLink="/admin/credential"><i nz-icon nzType="left" nzTheme="outline"></i> Previous Page </a>
  </div>
</div>
<nz-card nzTitle="Credential Detail" class="credential-detal" [nzExtra]="genetateCredential">
	<nz-table [nzData]="credentialRecipientDetail" [nzLoading]="loading"	class="table-credential-detail">
		<tbody>
				<tr>
					<td width="20%" class="label">Name :</td>
					<td>{{ credentialRecipientDetail.name }}</td>
				</tr>
				<tr>
					<td width="20%" class="label">Registry Identifier :</td>
					<td>{{ credentialRecipientDetail.registry_identifier }}</td>
				</tr>
				<tr>
					<td width="20%" class="label">Description :</td>
					<td> {{ description }} </td>
				</tr>
				<tr>
					<td width="20%" class="label">Organization :</td>
					<td>{{ credentialRecipientDetail.organization.name }}</td>
				</tr>
				<tr>
					<td width="20%" class="label">Provider :</td>
					<td>{{ credentialRecipientDetail.provider.name }}</td>
				</tr>
				<tr>
					<td width="20%" class="label">Type :</td>
					<td> {{ credentialRecipientDetail.type }} </td>
				</tr>
				<tr>
					<td width="20%" class="label">Status :</td>
					<td> {{ credentialRecipientDetail.status }} </td>
				</tr>
				<tr>
					<td width="20%" class="label">Total Credential :</td>
					<td> {{ credentialRecipientDetail.total_credential }} </td>
				</tr>
		</tbody>
	</nz-table>
</nz-card>
<ng-template #genetateCredential>
	<div class="genetate-credential-button">
		<button nz-button nzType="primary" class="btn-primary" routerLink="/admin/credential/{{paramId}}/generate">
			Generate Credential
		</button>
	</div>
</ng-template>

<nz-card nzTitle="Credential Recipient" [nzExtra]="searchFilter" class="credential-recipient">
	<nz-table
		class="table-credential-recipient"
		#CDRecipientList
		[nzData]="credentialRecipientList"
		[nzNoResult]="noDataTemplate"
		[nzLoading]="loading"
		nzTableLayout="auto"
		(nzQueryParams)="queryParamsChange($event)"
		[nzFrontPagination]="false"
		[nzTotal]="recipientTotal"
		[nzPageSize]="recipientPageSize"
		[nzPageIndex]="recipientPage"
	>
		<thead>
			<tr>
				<th *ngFor="let column of listOfColumn" [nzColumnKey]="column.key" [nzSortFn]="true">{{ column.title }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let data of CDRecipientList.data;">
				<td>
					<a routerLink="/admin/credential/{{ data.id }}/detail">{{ data.subject_name }}</a>
				</td>
				<td>{{ data.subject_id }} </td>
				<td>{{ data.status }}</td>
				<td>{{ data.issuuance_date | date:'dd/MM/yyyy' }}</td>
				<td>{{ data.revoked_date | date:'dd/MM/yyyy' }}</td>
			</tr>
		</tbody>
	</nz-table>
	<ng-template #noDataTemplate>
		<nz-empty nzNotFoundContent="No Data"></nz-empty>
	</ng-template>
</nz-card>

<ng-template #searchFilter>
	<div class="search-filter">
		<nz-input-group class="search-box">
			<nz-input-group class="search-box" [nzSuffix]="suffixIconSearch">
				<input type="text" nz-input placeholder="Search Name or Email" (change)="searchRecipient($event)" />
			</nz-input-group>
			<ng-template #suffixIconSearch>
				<i nz-icon nzType="search"></i>
			</ng-template>
			<nz-select [(ngModel)]="selectedValue" [compareWith]="compareFn" (ngModelChange)="handleStatus($event)" nzAllowClear [nzPlaceHolder]="placeholder">
				<nz-option *ngFor="let option of optionList" [nzValue]="option" [nzLabel]="option.label"></nz-option>
			</nz-select>
			<ng-template #placeholder>
				<i nz-icon nzType="filter" nzTheme="fill"></i>
				 ตัวกรอง
			</ng-template>
		</nz-input-group>
	</div>
</ng-template>