import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { CredentialService } from '../../../service/credential.service';
import { Credential, CredentialMetadata } from '../../../models/credential';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-admin-credential-detail',
  templateUrl: './admin-credential-detail.component.html',
  styleUrls: ['./admin-credential-detail.component.scss']
})
export class AdminCredentialDetailComponent implements OnInit {
  id: string;
  learningopp_id: string;
  title: string;
  listOfData = [];
  credential: Credential;
  credentialStatus: string;
  downloadFile: string = "";
  currentDate: string = "";

  isDownloadButtonJustClicked: boolean = false;

  isRevokeConfirmModalShow: boolean = false;
  isRevoking: boolean = false;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private credentialService: CredentialService,
    private _location: Location,
    private notification: NzNotificationService,
    public datepipe: DatePipe
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });
    this.currentDate = this.datepipe.transform(Date.now(), 'yyyyMMdd')
   }

  ngOnInit(): void {
    this.getCredential(this.id)
  }

  getCredential(id: string): void {
    this.credentialService.getCredential(id).subscribe(credential => {
      this.credential = credential;
      this.title = credential.name;
      this.credentialStatus = credential.status;
      this.downloadFile = credential.evidence_file.replace(/^http(.*):/g, "");

      this.getMetadata(credential)
      this.getLearningOpp(credential.from_learning_opp_id)
    })
  }

  getLearningOpp(id: string): void {
    this.credentialService.getCredentialSchema(id).subscribe(learningopp => {
      this.learningopp_id = learningopp.id;
    })
  }

  getMetadata(credential: Credential): void {
    let credentialList = []
    credentialList.push(
      {
        key: 'Name',
        value: credential.name,
        order: 1
      },
      {
        key: 'Recipient',
        value: credential.subject_name,
        order: 2
      },
      {
        key: 'E-mail',
        value: credential.subject_id,
        order: 3
      },
      {
        key: 'Date Recieve',
        value: this.datepipe.transform(credential.created_date, 'dd/MM/yyyy'),
        order: 4
      },
      {
        key: 'Isuuer',
        value: credential.issuer,
        order: 5
      },
      {
        key: 'Evidence Type',
        value: credential.evidence_type,
        order: 6
      },
      {
        key: 'Expiration Date',
        value: this.datepipe.transform(credential.expiration_date, 'dd/MM/yyyy'),
        order: 7
      },
      {
        key: 'Holder',
        value: credential.holder,
        order: 8
      },
      {
        key: 'type',
        value: credential.type,
        order: 9
      },
      {
        key: 'Status',
        value: credential.status,
        order: 10
      },
      {
        key: 'Updated Date',
        value: this.datepipe.transform(credential.updated_date, 'dd/MM/yyyy'),
        order: 11
      },
      {
        key: 'Updated By',
        value: credential.updated_by,
        order: 12
      },
      {
        key: 'Revoked Date',
        value: this.datepipe.transform(credential.revoked_date, 'dd/MM/yyyy'),
        order: 13
      },
      {
        key: 'Revoked By',
        value: credential.revoked_by,
        order: 14
      },
    )
    this.listOfData = credentialList;
    this.listOfData.sort((a, b) => (a.order < b.order ? -1 : 1));
  }

  updateStatus(status: string): void {
    let data: any = {'status': status};
    this.credentialService.updateStatusCredential(data, this.id).subscribe(data => {
      this.isRevoking = false;
      if (!data['error']) {
        this.createNotification('success', 'เพิกถอนใบรับรองผลการเรียนสำเร็จแล้ว', 'ใบรับรองผลการเรียน ' + this.title + ' ถูกเพิกถอนเรียบร้อยแล้ว');
        this.ngOnInit();
      }
    });
  }

  showChangeStatusModal(): void {
    this.isRevokeConfirmModalShow = true;
    this.isRevoking = true;
  }

  handleCancel(): void {
    this.isRevokeConfirmModalShow = false;
    this.isRevoking = false;
  }

  handleChangeStatus(status: string): void {
    this.updateStatus(status);
    this.isRevokeConfirmModalShow = false;
  }

  handleDownloadCredentialClicked(): void {
    if (this.isDownloadButtonJustClicked) return;

    let linkElement: HTMLElement = document.getElementById('hidden-download-credential-link') as HTMLElement;
    linkElement.click();

    // Disable download button for 1 sec.
    this.isDownloadButtonJustClicked = true;
    setTimeout(() => {
      this.isDownloadButtonJustClicked = false;
    }, 1500);
  }

  createNotification(type: string, title: string, message: string): void {
    this.notification.create(
      type,
      title,
      message
    );
  }
}
